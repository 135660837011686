<template>
  <div>
    <div
      v-if="data && data.length > 0"
      class="project-table pb-4 overflow-auto"
    >
      <a-table
        tableLayout="fixed"
        class="project-list-table"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :rowKey="(record) => `project-management-${record.id}`"
        :customRow="
          (record) => {
            return {
              on: {
                click: (event) => onSelect(record),
              },
            };
          }
        "
        :rowClassName="
          (record) =>
            record.is_deleted || record.status === 4 ? 'opacity-60 pointer-events-none' : 'h-max'
        "
      >
        <!-- <span slot="img" slot-scope="text">
          <img class="object-cover" :src="text" />
        </span> -->
        <span slot="stage_title">{{ $t('COLUMN_STAGE') }}</span>
        <span slot="project_name_title">{{ $t('COLUMN_PROJECT_NAME') }}</span>
        <span slot="sold_title">{{ $t('COLUMN_SOLD') }}</span>
        <span slot="presale_end_title">{{ $t('COLUMN_PRESALE_END') }}</span>
        <span slot="pubsale_end_title">{{ $t('COLUMN_PUBSALE_END') }}</span>
        <div
          slot="status_code"
          slot-scope="text, record"
        >
          <div class="flex flex-col items-center">
            <div class="flex items-center">
              <div
                v-if="!record.is_hidden"
                class="w-3 h-3 rounded-full bg-green-500 mr-2"
              ></div>

              <template v-if="record.is_deleted"> Deleted </template>
              <template v-else-if="record.status === 4"> Stopped </template>
              <template v-else-if="isSoldOut(record)">{{ $t("SOLD_OUT") }}</template>
              <template v-else>
                <div
                  v-if="!record.error_message"
                  class="flex items-center w-max"
                >
                  {{ $t(text) }}
                </div>
                <div v-else>
                  <a-tooltip placement="bottom">
                    <template #title>
                      {{ $t(record.error_message) }}
                    </template>
                    <div class="flex items-center w-max">
                      {{ $t(text) }}
                      <InfoIcon class="w-4 h-4 ml-1" />
                    </div>
                  </a-tooltip>
                </div>
              </template>
            </div>

            <div class="mt-2">
              <img
                class="object-cover h-11 w-32"
                :src="record.banner_url"
              />
            </div>
          </div>
        </div>
        <div
          slot="name"
          slot-scope="text, record"
        >
          <span>{{ locale === 'ja' && record.name_ja ? record.name_ja : record.name }}</span>
        </div>
        <div
          slot="sold"
          slot-scope="text, record"
        >
          <ProjectTableSoldColumnContent :record="record" />
        </div>
        <div
          slot="presaleEnd"
          slot-scope="text, record"
        >
          <div class="date">
            <template v-if="text">
              {{ text | toJST('MM/DD/YYYY HH:mm [(JST)]') }}
            </template>
            <template v-else-if="record.is_presale_tba">
              <div>{{ $t('TBA') }}</div></template
            >
          </div>
        </div>
        <div
          slot="publicSaleEnd"
          slot-scope="text, record"
        >
          <div class="date">
            <template v-if="text">
              {{ text | toJST('MM/DD/YYYY HH:mm [(JST)]') }}
            </template>
            <template v-else-if="record.is_publicsale_tba">
              <div>{{ $t('TBA') }}</div></template
            >
          </div>
        </div>
        <div
          slot="action"
          slot-scope="text, record"
          class="flex justify-end"
        >
          <button
            v-if="(record.status <= 1 || record.status === 5) && userRole === 'admin'"
            @click.stop="$router.push(`/manage/projects/${record.id}/edit`)"
            class="flex items-center justify-center rounded-lg border border-white p-2 w-24 edit-btn !hover:text-primary !hover:border-primary"
          >
            <EditIcon class="mr-2" /> {{ $t('EDIT') }}
          </button>
        </div>
      </a-table>
    </div>
    <a-empty
      class="mt-32"
      v-else
    ></a-empty>
  </div>
</template>

<script>
import ProjectTableSoldColumnContent from './ProjectTableSoldColumnContent.vue';
import { mapGetters, mapState } from 'vuex';
import { compareAddress } from '@/utils/address.util';
import InfoIcon from '../icons/info.icon.vue';
import EditIcon from '../icons/edit.icon.vue';

const columns = [
  {
    dataIndex: 'status_code',
    key: 'status_code',
    scopedSlots: { customRender: 'status_code' },
    slots: { title: 'stage_title' },
    align: 'center',
    width: 200,
  },
  {
    dataIndex: 'name',
    key: 'name',
    slots: { title: 'project_name_title' },
    scopedSlots: { customRender: 'name' },
  },
  {
    dataIndex: 'sold',
    key: 'sold',
    scopedSlots: { customRender: 'sold' },
    slots: { title: 'sold_title' },
    align: 'right',
    width: 150,
  },
  {
    dataIndex: 'presale_end_time',
    key: 'presale_end_time',
    scopedSlots: { customRender: 'presaleEnd' },
    slots: { title: 'presale_end_title' },
    align: 'center',
    width: 200,
  },
  {
    dataIndex: 'publicsale_end_time',
    key: 'publicsale_end_time',
    scopedSlots: { customRender: 'publicSaleEnd' },
    align: 'center',
    slots: { title: 'pubsale_end_title' },
    width: 200,
  },
  {
    dataIndex: 'action',
    key: 'action',
    title: '',
    scopedSlots: { customRender: 'action' },
    align: 'right',
  },
];

export default {
  name: 'ProjectTable',
  components: { ProjectTableSoldColumnContent, InfoIcon, EditIcon },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns,
    };
  },
  computed: {
    ...mapGetters({
      account: 'wallet/account',
      userRole: 'wallet/loggedUserRole',
    }),
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
  },
  methods: {
    onSelect(record) {
      if (record.is_deleted || record.status === 4) return;
      // this.$router.push(`/manage/projects/${record.id}`);
      const { status_code, owner_address, id } = record;

      if (compareAddress(owner_address, this.account)) {
        if (status_code === 'NEW' || status_code === 'READY') {
          this.$router.push(`/manage/user-projects/${id}/basic-info`);
        } else if (status_code !== 'ERROR') {
          this.$router.push(`/manage/user-projects/${id}/sale-info`);
        } else {
          this.$router.push(`/manage/projects/${id}`);
        }
      } else {
        this.$router.push(`/manage/projects/${id}`);
      }
    },
    isSoldOut(record) {
      return (
        Number(record.presale_amount) + Number(record.publicsale_amount) ===
        Number(record.presale_sold_amount) + Number(record.publicsale_sold_amount)
      );
    },
  },
};
</script>

<style lang="scss">
.project-table {
  .ant-table {
    min-width: 992px;
    th {
      white-space: nowrap;
    }
    &-body {
      .ant-table-thead {
        tr > th {
          @apply px-2 pt-0 pb-6;
          @apply mx-0;
          background: transparent;
          border: none;
          @apply text-white text-base;

          &:last-child {
            @apply pr-6;
          }
        }
      }
      .ant-table-tbody {
        tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          @apply bg-white opacity-60 text-black;
          .edit-btn {
            @apply border-black text-black;
          }
        }
        tr > td {
          @apply cursor-pointer;
          @apply px-2;
          @apply mx-0;
          @apply bg-transparent text-white border-none;
          @apply py-6;
          @apply text-base;

          &:last-child {
            @apply pr-6;
          }

          .date {
            min-width: 190px;
            @apply flex items-center justify-center;
          }
        }
        tr {
          &:nth-child(even) {
            background: #27154c;
          }
          &:nth-child(odd) {
            background: #321d60;
          }
        }
      }
    }
  }
}
</style>

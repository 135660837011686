import { render, staticRenderFns } from "./project-management.page.vue?vue&type=template&id=0f5916bc&scoped=true&"
import script from "./project-management.page.vue?vue&type=script&lang=js&"
export * from "./project-management.page.vue?vue&type=script&lang=js&"
import style0 from "./project-management.page.vue?vue&type=style&index=0&id=0f5916bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5916bc",
  null
  
)

export default component.exports
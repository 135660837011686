<template>
  <div class="paginator">
    <button
      v-if="!isFirst"
      @click="onChange(1)"
      class="jumper text-2xl relative mr-1 cursor-pointer"
    >
      &lt;&lt;
    </button>
    <a-pagination
      v-model="currentPage"
      :total="total"
      :defaultPageSize="pageSize"
      show-less-items
      @change="onChange"
      :item-render="itemRender"
      :class="{
        'last-page': isLast,
        'first-page': isFirst,
      }"
    />
    <button
      v-if="!isLast"
      @click="onChange(Math.ceil(total / pageSize))"
      class="jumper text-2xl relative ml-1 cursor-pointer"
    >
      &gt;&gt;
    </button>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.current,
    };
  },
  computed: {
    isFirst() {
      return this.current === 1;
    },
    isLast() {
      return this.current * this.pageSize >= this.total;
    },
  },
  methods: {
    onChange(page) {
      this.$emit("onChange", page);
      this.currentPage = page;
    },
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>&lt;</a>;
      } else if (type === "next") {
        return <a>&gt;</a>;
      }
      return originalElement;
    },
  },
};
</script>

<style lang="scss">
.paginator {
  @apply font-medium;
  .jumper {
    top: -6px;
  }
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    .jumper {
      top: 0;
      font-size: 1.2rem;
      margin: 5px 0;
    }
  }
}
.ant-pagination {
  @apply text-2xl;
  display: inline-block;
  &-item {
    background: #928f8f;
    border-radius: 4px;
    border: 1px solid #aaa;
    margin: 0 10px;
    a {
      color: white;
      padding: 4px 8px;
      @apply text-xl;
    }
  }
  &-item-active {
    background: #ff6f91;
    border-color: #ff6f91;
  }
  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #928f8f;
  }

  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: rgba(159, 232, 255, 1);
    @apply relative;
    @apply text-xl;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    a {
      border: none;
      color: #928f8f;
      background: transparent;
      @apply relative;
      font-size: 1.2rem;
      font-family: "Poppins", sans-serif;
    }
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;

    &-item {
      margin: 0 5px;
      a {
        padding: 4px 6px;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      a {
        font-size: 1.2rem;
      }
    }
  }
}

.jumper {
  color: #928f8f;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin: 5px 0;
  }
}

.last-page {
  .ant-pagination-next {
    @apply hidden;
  }
}
.first-page {
  .ant-pagination-prev {
    @apply hidden;
  }
}
</style>

<template>
  <div class="flex items-center justify-end">{{ sold }}</div>
</template>

<script>
import { BigNumber } from "@/utils/bignumber.util";

export default {
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sold: "",
    };
  },

  mounted() {
    this.sold = this.getSoldAmountFromTime();
  },
  methods: {
    getSoldAmountFromTime() {
      const presaleSold = this.record.presale_sold_amount || 0;
      const publicSold = this.record.publicsale_sold_amount || 0;
      const total = new BigNumber(this.record.presale_amount)
        .plus(this.record.publicsale_amount)
        .toString();

      const totalSold = new BigNumber(presaleSold).plus(publicSold).toString();

      return `${totalSold}/${total}`;
    },
  },
};
</script>

<style></style>

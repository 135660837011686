<template>
  <a-modal
    title=""
    :footer="null"
    wrapClassName="modal-csv"
    :visible="visible"
    @cancel="handleCancel"
    destroyOnClose
    centered
  >
    <section class="title text-xl font-medium">
      {{ $t('CSV_DOWNLOAD_MODAL_TITLE') }}
      <hr />
    </section>
    <section class="body pt-28 pb-10">
      <ValidationObserver ref="observer">
        <form
          class="mx-auto"
          @submit.prevent="onSubmitForm"
        >
          <div class="grid xl:grid-cols-5 grid-cols-1">
            <div class="col-span-2 xl:text-right text-center">
              <ValidationProvider
                :rules="{
                  required: startTime,
                }"
                v-slot="{ errors }"
                name="CSV_START_TIME_FIELD"
              >
                <BaseDatepicker
                  id="csv_start"
                  v-model="startTime"
                  :disabledDateFunc="disabledDateFunc"
                  class="inline-block"
                  calendarClass="text-white relative -top-1 mr-3 pr-4"
                />
                <div
                  v-if="startTime"
                  class="text-left inline-block rounded-md text-xl bg-white text-black py-2 w-60"
                >
                  {{ startTime | toJST }}
                </div>
                <div
                  v-else
                  class="text-left inline-block rounded-md text-xl bg-white text-gray-500 py-2 w-60"
                >
                  yyyy/mm/dd
                </div>
                <ErrorMessage
                  id="csv_start"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
            <div class="text-center text-3xl">-</div>
            <div class="col-span-2 xl:text-left text-center">
              <ValidationProvider
                :rules="{
                  required: endTime,
                }"
                v-slot="{ errors }"
                name="CSV_END_TIME_FIELD"
              >
                <BaseDatepicker
                  id="csv_end"
                  :disabledDateFunc="disabledDateFuncEndTime"
                  v-model="endTime"
                  class="inline-block"
                  calendarClass="text-white relative -top-1 mr-3 pr-4"
                />
                <div
                  v-if="endTime"
                  class="text-left inline-block rounded-md text-xl bg-white text-black py-2 w-60"
                >
                  {{ endTime | toJST }}
                </div>
                <div
                  v-else
                  class="text-left inline-block rounded-md text-xl bg-white text-gray-500 py-2 w-60"
                >
                  yyyy/mm/dd
                </div>
                <ErrorMessage
                  id="csv_end"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="mx-auto text-center mt-20">
            <button
              class="bg-primary text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl"
              type="submit"
              :disabled="loading"
            >
              {{ $t('CSV_DOWNLOAD_BTN') }}
            </button>
          </div>
        </form>
      </ValidationObserver>
    </section>
  </a-modal>
</template>

<script>
import BaseDatepicker from '@/components/common/BaseDatepicker.vue';
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import { downloadCSV } from '@/services';
import { BigNumber } from '@/utils/bignumber.util';
import UtilNotify from '@/utils/notify.util';
export default {
  components: {
    BaseDatepicker,
    ErrorMessage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startTime: null,
      endTime: null,
      loading: false,
    };
  },
  watch: {
    startTime() {
      this.endTime = null;
    },
  },
  methods: {
    handleCancel() {
      this.$emit('onCancel');
    },
    disabledDateFunc(date) {
      return date > new Date();
    },
    disabledDateFuncEndTime(date) {
      return date <= this.startTime || date > new Date();
    },
    async onSubmitForm() {
      try {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) return;
        if (this.loading) return;
        this.loading = true;
        const startTime = new BigNumber(this.startTime).div(1000).toNumber();
        const endTime = new BigNumber(this.endTime).div(1000).toNumber();
        const data = await downloadCSV(startTime, endTime);
        this.loading = false;
        if (data) {
          const href = URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'report.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.$emit('onCancel');
        }
      } catch (error) {
        this.loading = false;
        UtilNotify.error(this.$notify, error);
      }
    },
  },
};
</script>

<style lang="scss">
.modal-csv {
  .ant-modal {
    @screen xl {
      width: 1000px !important;
    }
  }
  .ant-modal-content {
    @apply rounded-3xl;
    background: rgba(39, 37, 125, 0.7);
    @apply text-white;

    .ant-modal-body {
      @screen xl {
        padding: 72px;
      }
    }
    .ant-modal-close-x {
      @apply text-white;
    }
  }
}
</style>
